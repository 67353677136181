import { cn } from "~/toolkit/components/utils";

type KnowledgeFeatureProps = {
  heading: string;
  description: string;
  image: string;
  direction?: "left" | "right";
  className?: string;
};

export default function KnowledgeFeature({
  heading,
  description,
  image,
  direction = "left",
  className = "",
}: KnowledgeFeatureProps) {
  return (
    <div
      className={cn(
        direction === "left"
          ? "md:flex-row"
          : "flex-col-reverse md:flex-row-reverse",
        "flex flex-col items-center gap-7 md:gap-14",
        className
      )}
    >
      <div className="basis-2/5">
        <h3 className="text-3xl font-bold text-gray-900 ">{heading}</h3>
        <div className="h-2 w-16 bg-secondary mt-2 mb-8"></div>
        <p className="text-pretty text-lg leading-8 text-gray-600">
          {description}
        </p>
      </div>
      <div
        className={cn(
          direction === "left" ? "justify-start" : "justify-end",
          "basis-3/5 flex"
        )}
      >
        <div className="p-4 border rounded-xl lg:rounded-2xl bg-gray-300/30 flex items-center justify-center">
          <img
            src={image}
            className="aspect-auto shadow-2xl rounded-xl lg:rounded-xl"
          />
        </div>
      </div>
    </div>
  );
}
