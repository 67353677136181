import { useLoaderData } from "@remix-run/react";
import { getStripeConfiguration } from "~/features/subscriptions/stripeConfiguration.server";
import { HomePageAppHeader } from "~/layout/components/HomePageAppHeader";
import { AppErrorBoundary } from "~/toolkit/components/errors/AppErrorBoundary";
import Footer from "../components/Footer";
import { GetStartedCTA } from "../components/GetStartedCTA";
import Hero from "../components/Hero";
import KnowledgeFeature from "../components/KnowledgeFeature";
import PricingTable from "../components/PricingTable";
import { ProblemStatement } from "../components/ProblemStatement";

export const HOMEPAGE_ROUTE = "features/marketing/routes/homepage.route.tsx";

export const loader = async ({}) => {
  let stripeConfig = await getStripeConfiguration();

  return {
    pricingEmbed: stripeConfig?.freebieUpgradePricingPageScript,
  };
};
export default function HomepageRoute() {
  let data = useLoaderData<typeof loader>();

  return (
    <div className="grid h-full grid-rows-[auto_1fr]">
      <HomePageAppHeader />
      <main className="relative top-16">
        <Hero />
        {/* <ProblemStatement />
        <Features />
        <Faqs /> */}
        <section id="problems">
          <ProblemStatement />
        </section>

        <section
          id="features"
          className="p-6 gap-14 mx-auto max-w-[1800px] flex flex-col md:gap-36"
        >
          <div className="mx-auto max-w-4xl text-center mt-12">
            <div className="text-lg font-semibold leading-7 text-primary">
              Time is money.
            </div>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Cut the fluff.
            </p>
          </div>

          <KnowledgeFeature
            heading="Raw Transcripts"
            description="Access complete raw transcripts of conference calls for review and reference."
            image="/images/dk-transcript-nvda.jpg"
            direction="left"
          />
          <KnowledgeFeature
            heading="Summarize"
            description="Receive concise, AI-powered summaries of transcripts in seconds."
            image="/images/dk-summarize-nvda.jpg"
            direction="right"
          />
          <KnowledgeFeature
            heading="Questionnaire"
            description="Set your queries on autopilot with our intelligent questionnaires."
            image="/images/dk-questionnaire-nvda.jpg"
            direction="left"
          />
          <KnowledgeFeature
            heading="Chat"
            description="Converse with the transcript for clarifications and deeper understanding."
            image="/images/dk-chat-nvda.jpg"
            direction="right"
          />
        </section>
        <section className="mt-16">
          <GetStartedCTA />
        </section>

        <section id="pricing" className="py-12 lg:py-24">
          <PricingTable />
          {/* <div className="my-24">
            <StripePricingEmbed embed={data.pricingEmbed} />
          </div> */}
        </section>
      </main>
      <Footer />
    </div>
  );
}

export const ErrorBoundary = AppErrorBoundary;
