import { Link } from "@remix-run/react";
import { LoginButton } from "~/auth/routes/login.route";
import { useCurrentUser } from "~/auth/useCurrentUser";

export default function Hero() {
  let currentUser = useCurrentUser();
  return (
    <div className="relative overflow-hidden bg-white isolate">
      <svg
        className="absolute block opacity-70 sm:opacity-100 sm:block inset-0 -z-10 h-full w-full stroke-gray-300 [mask-image:radial-gradient(circle_at_0%_center,_transparent_5%,_#00000099_85%)] [-webkit-mask-image:radial-gradient(circle_at_0%_center,_transparent_5%,_#00000099_85%)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-200/70">
          <path
            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
        />
      </svg>

      <div
        className="absolute top-0 right-0 -ml-24 overflow-hidden left-1/2 -z-10 transform-gpu blur-3xl lg:ml-24 xl:ml-48"
        aria-hidden="true"
      ></div>
      <div className="px-6 pt-10 pb-24 mx-auto max-w-[1800px] sm:pb-32 lg:flex lg:px-8 lg:py-24">
        <div className="max-w-2xl mx-auto lg:mx-0 lg:max-w-xl lg:w-[45rem] lg:pt-8 lg:pl-8">
          {/* <img
            className="h-11"
            src="https://tailwindui.com/img/logos/mark.svg?color=yellow&shade=500"
            alt="Your Company"
          /> */}
          <div>
            <div className="text-lg font-semibold leading-6 text-gray-600">
              Portfolio Managers and Analysts
            </div>
          </div>
          <h1
            id="hero"
            className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl 2xl:text-7xl flex flex-wrap gap-2 items-center"
          >
            <span className="text-gray-600 font-semibold">Find your</span>
            <span className="">edge</span>
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600 text-pretty">
            {/* Turn the numbers into knowledge. Summarize, question, and understand
            market data. */}
            AI-powered conference call transcripts, summaries, and insights in
            seconds.
          </p>
          <div className="flex items-center mt-10 gap-6 flex-wrap">
            {currentUser ? (
              <span></span>
            ) : (
              <LoginButton
                returnToPath="/sessions/new"
                intent="signup"
                className="normal-case btn btn-secondary"
              >
                Create a free account!
              </LoginButton>
            )}
            <Link
              to="#the-problem"
              className=" hidden text-sm font-semibold leading-6 text-gray-900"
            >
              Learn more <span aria-hidden="true">›</span>
            </Link>
          </div>
        </div>
        <div className="flex flex-grow max-w-2xl mx-auto mt-16 sm:mt-24 lg:ml-10 lg:mt-0 lg:max-w-none xl:ml-32 ">
          <div className="p-4 border rounded-xl lg:rounded-2xl bg-gray-300/30">
            <video
              autoPlay
              muted
              playsInline
              loop
              aria-label="Video showing a how we can summaraize, question, and chat with earnings calls."
              className="w-full shadow-2xl rounded-xl lg:rounded-2xl"
              src="https://public-files.droopy.dev/dotadda-knowledge-hero-02.mp4"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
