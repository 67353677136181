import { tw } from "~/toolkit/components/tw";
import { cn } from "~/toolkit/components/utils";

export function ProblemStatement({}) {
  return (
    <div className="bg-base-200">
      <div className="px-6 py-12 sm:px-6 sm:py-24 lg:px-8">
        <div className="mx-auto text-center space-y-4 sm:space-y-12">
          <ul className="mx-auto text-5xl md:text-7xl font-extrabold leading-[58px] md:leading-[86px] text-gray-800 grid grid-cols-1 gap-24">
            <li className="">
              Drowning in <mark className="bg-[#ffd962]">conference calls</mark>
              ?
            </li>
            <li className="">
              Manually analyzing{" "}
              <mark className="bg-[#ffd962]">transcripts</mark>?
            </li>
          </ul>
          {/* <div className="text-base font-semibold leading-6 text-gray-600 pt-8">
            Time is money.
          </div> */}
        </div>
      </div>
    </div>
  );
}

const rawInputs = [
  {
    body: "We need more personalization.",
    author: {
      name: "An executive",
      handle: "lesliealexander",
      imageUrl:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    body: "The button didn't work.",
    author: {
      name: "Helpdesk ticket",
      handle: "lindsaywalton",
      imageUrl:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    body: "I want to be able to chat with others.",
    author: {
      name: "Business stakeholder",
      handle: "tomcook",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  // More testimonials...
  {
    body: "It's really slow!",
    author: {
      name: "Feedback survey",
      handle: "leonardkrasner",
      imageUrl:
        "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    body: "Can we add AI?",
    author: {
      name: "Someone from Sales",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
];

function GradientBackground() {
  return (
    <>
      <div
        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#D4925A] to-[#F0CC54]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        aria-hidden="true"
      >
        <div
          className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#D4925A] to-[#F0CC54] xl:ml-0 xl:mr-[calc(50%-12rem)]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
    </>
  );
}

function RawInputs() {
  return (
    <CardColumn>
      {rawInputs.map((rawInput, index) => (
        <Card key={rawInput.author.name} className="">
          <div className="flex gap-4 items-center">
            <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src={rawInput.author.imageUrl}
              alt=""
            />
            {/* <div>
                    <div className="font-semibold text-gray-600 text-sm">
                      {rawInput.author.name}
                    </div>
                  </div> */}
            <blockquote className="text-gray-900 text-lg italic">
              <p>{`“${rawInput.body}”`}</p>
            </blockquote>
          </div>
          <div className="mt-4 text-gray-500 text-sm">
            {rawInput.author.name}
          </div>
        </Card>
      ))}
    </CardColumn>
  );
}

function WorkItem({
  title,
  bullets,
  className,
  checkColor,
}: {
  title: string;
  bullets: string[];
  className?: string;
  checkColor: string;
}) {
  return (
    <div className="inline-grid stack place-items-center relative">
      <Card className={cn("h-full", className)}>
        <h3 className="font-bold text-lg">
          {title}
          <span className="text-gray-500 text-base font-normal relative -top-1">
            *
          </span>
        </h3>
        <ul className="mt-4">
          {bullets.map((bullet, index) => (
            <li key={index} className="flex items-center">
              <span className={cn(checkColor, "text-xl mr-2 ")}>☑︎</span>
              <span className="text-gray-600">{bullet}</span>
            </li>
          ))}
        </ul>
      </Card>
      <Card
        className={cn(className, "h-full origin-bottom")}
        style={{
          transform: "translateY(4px) scale(.97) !important",
        }}
      ></Card>
      <Card
        className={cn(className, "h-full origin-bottom")}
        style={{
          transform: "translateY(7px) scale(.92) !important",
        }}
      ></Card>
    </div>
  );
}

function WorkItems() {
  return (
    <>
      <CardColumn>
        <WorkItem
          title="Features"
          bullets={["Motivation", "Bounded Scope", "Tech Strategy"]}
          className="border-2 border-fuchsia-500/50"
          checkColor="text-fuchsia-500"
        />
        <WorkItem
          title="User Stories"
          className="border-2 border-blue-400/70"
          bullets={["Description", "Acceptance Criteria", "Test Cases"]}
          checkColor="text-blue-400"
        />
        <WorkItem
          title="Bugs"
          className="border-2 border-red-400/70"
          bullets={["Replication Steps", "Expected Behavior"]}
          checkColor="text-red-400"
        />
        <div className="pt-8 xl:pt-6 grid grid-cols-[auto_auto] leading-6 text-gray-600 text-sm gap-1">
          <span className="font-semibold text-base">*</span>
          <p>
            Or whatever YOU call them. Epics, Projects, Issues, etc...
            <br />{" "}
            <span className="">
              You'll configure AIBacklog to match your company's preferences.
            </span>
          </p>
        </div>
      </CardColumn>
    </>
  );
}

const CardColumn = tw(
  "div",
  "w-full flex flex-wrap justify-center lg:flex-col gap-2 gap-y-4 lg:gap-8 leading-6 text-gray-900 "
);
const Card = tw(
  "figure",
  "rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
);
